import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import BannerBackgroundImage from '@images/banner-bg.png';

const BannerBackground = styled.div`
  position: relative;
  background-image: url(${BannerBackgroundImage});
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  height: 150px;
  @media (max-width: 767px) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    & > div {
      padding-left: 0.75rem;
    }
  }
`;

const BannerHeading = styled.h1`
  color: #8c001a;
  font-size: 2.5rem;
  @media (max-width: 1023px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

const BannerContainer = (props) => {
  const { title, image } = props;

  return (
    <BannerBackground>
      <Row className="h-100 gx-0">
        <Col xs="12" md="10" lg="8" className="mx-auto">
          <div className="d-flex h-100 align-items-center justify-content-between">
            <BannerHeading>{title}</BannerHeading>
            {image ? (<img src={image} alt="Emote" height={150} />) : null}
          </div>
        </Col>
      </Row>
    </BannerBackground>
  );
};

BannerContainer.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
};

export default BannerContainer;
