/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Container, Nav, CloseButton } from 'react-bootstrap';
import styled from 'styled-components';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import BannerContainer from '@components/bannerContainer';

const OrderedList = styled.ol`
  counter-reset: list;
  & > li {
    list-style: none;
    margin-bottom: 1rem;
    &:before {
      content: counter(list, decimal) '. ';
      counter-increment: list;
      font-weight: bold;
    }
  }
`;

const TermsPage = () => {
  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: (
          <Nav.Link onClick={() => navigate(-1)} className="ms-3">
            <CloseButton />
          </Nav.Link>
        ),
      }}
    >
      <SEO title="Términos y condiciones" />

      <Container>
        <BannerContainer title="Términos y condiciones" />

        <Row>
          <Col xs="12" md="10" lg="8" className="mx-auto py-4">
            <p>
              Por favor lea cuidadosamente estos Términos y condiciones de
              Pizzería Habana antes de crearse una cuenta y realizar una compra
              o utilizar nuestros servicios (los "Servicios") a través del sitio
              web www.pizzeriahabana.com (el "sitio" o "sitio web"), la
              aplicación móvil de Pizzería Habana (la "aplicación" o "aplicación
              móvil") o cualquier otro medio disponible. Estos términos
              representan un acuerdo entre usted (el "cliente" o "usted") y
              Pizzería Habana. Al crear una cuenta y/o utilizar nuestros
              servicios, usted acepta estar sujeto a todos los términos
              establecidos a continuación. Los términos pueden ser actualizados
              periódicamente por Pizzeria Habana. Si usted está en desacuerdo
              con estos términos, no registre una cuenta en Pizzería Habana, de
              tenerla ya, inmediatamente interrumpa el acceso al sitio y a la
              aplicación, no utilice los servicios y notifíquelo por medio de un
              correo electrónico a soporte@pizzeriahabana.com o llamando al
              teléfono de atención al cliente al (305) 859-4472.
            </p>
            <h4>Advertencia</h4>
            <p>
              Es un delito adquirir un producto o servicio por medios
              fraudulentos. Todos y cada uno de los productos/servicios
              comprados a través de este sitio web o la aplicación no se
              utilizarán para fines ilegales. En caso de que Pizzería Habana
              tenga conocimiento de transacciones o compras fraudulentas, se
              reserva el derecho de enviar toda la información disponible a la
              víctima o a la entidad de aplicación de la ley ante cualquier
              solicitud legal, sin el consentimiento previo del cliente. Este es
              un término incondicional e innegociable, y una excepción a
              nuestras políticas de privacidad.
            </p>
            <h4>Aceptación de los términos y condiciones</h4>
            <h4 className="my-4">
              I. USO DE UNA CUENTA DE PIZZERÍA HABANA DESDE EL SITIO WEB, LA
              APLICACIÓN MÓVIL O MEDIANTE LLAMADA TELEFÓNICA.
            </h4>
            <p>
              Si utiliza los Sitios Web o Aplicaciones, usted es responsable de
              mantener la confidencialidad de su cuenta y contraseña y de
              restringir el acceso a su computadora, teléfono inteligente o
              tableta, y usted acepta la responsabilidad por todas las
              actividades que ocurran bajo su cuenta o contraseña. Los Sitios
              Web y Aplicaciones venden productos a adultos, que pueden comprar
              con una tarjeta de crédito. Si usted es menor de 18 años, puede
              usar los Sitios Web y Aplicaciones solo con la inclusión de un
              padre o tutor. Pizzería Habana se reservan el derecho a negar el
              servicio, cancelar cuentas, eliminar o editar contenido o cancelar
              pedidos a su única discreción.
            </p>
            <p>
              Al crear una cuenta o utilizar el servicio de Pizzería Habana en
              el sitio web o aplicación móvil, usted afirma que cuenta con la
              edad legal para participar en este acuerdo y que ha leído y
              entendido completamente los términos y condiciones. El cliente
              está de acuerdo que toda venta de bienes y/o servicios a través
              del sitio web y/o por la aplicación móvil de Pizzería Habana es
              final y no es reembolsable, siempre y cuando el cliente haya
              realizado la orden a través de su cuenta en Pizzería Habana.
            </p>
            <p>
              <b>A. Firma Electrónica</b> - Estos Términos y Condiciones están
              sujetos a la Ley Federal de Firma Electrónica de 2000, según
              enmienda, y se acuerda que constituye un acuerdo entre Pizzería
              Habana y usted.
            </p>
            <p>
              <b>
                B. Los Términos y condiciones se aplican a todos los servicios y
                ventas
              </b>{' '}
              - La prestación de servicios ofrecidos a través del sitio web, la
              aplicación móvil o cualquier otro medio disponible, por ejemplo, a
              través de una llamada telefónica, está sujeta a la aceptación de
              estos Términos. Al hacer clic en "Crear cuenta" el cliente acepta
              expresamente que quedará vinculado por los Términos aplicables a
              los servicios prestados por Pizzería Habana, y a la Política de
              Privacidad. Asegúrese de leer estos términos detenidamente antes
              de acceder a los servicios de Pizzería Habana o registrarse como
              nuevo usuario (crear una cuenta).
            </p>
            <p>
              <b>C. Ley aplicable, jurisdicción y lugar</b> - El presente
              acuerdo y los términos y condiciones se regirán exclusivamente y
              se interpretarán en estricta conformidad con las leyes del Estado
              de Florida, Estados Unidos, sin tener en cuenta las teorías de
              conflictos de leyes, la jurisprudencia o los principios. El
              usuario, en virtud del registro de la cuenta, el uso o la compra a
              través del sitio web/aplicación acepta someterse exclusivamente a
              la jurisdicción de los tribunales estatales y federales del
              condado de Miami-Dade, y renuncia expresamente a todas las
              defensas a la jurisdicción personal de dichos tribunales.
            </p>
            <p>
              <b>D. Uso legal de la cuenta, sitio y aplicación</b> - El cliente
              acepta y asegura que no utilizará este sitio web o la aplicación
              móvil de pizzería Habana de alguna manera que cause, o pueda
              causar, daños o menoscabo de la disponibilidad o accesibilidad del
              sitio y de su aplicación; así como de cualquier manera que sea
              ilegal, fraudulenta o dañina, o en relación con cualquier
              propósito o actividad ilegal, fraudulenta o dañina. El cliente
              acepta y garantiza que no utilizará este sitio web para copiar,
              almacenar, alojar, transmitir, enviar, usar, publicar o distribuir
              cualquier material que consista (o esté vinculado a) cualquier
              software informático malicioso. El cliente acepta no realizar
              ninguna actividad de recopilación de datos sistemática o
              automatizada en o en relación con este sitio web sin el
              consentimiento previo por escrito de Pizzería Habana (el correo
              electrónico no constituye un consentimiento por escrito). El
              cliente no utilizará este sitio web o aplicación móvil para
              transmitir o enviar comunicaciones comerciales no solicitadas ni
              para ningún propósito relacionado con el marketing sin el
              consentimiento expreso por escrito de Pizzería Habana. El cliente
              puede ver, descargar información e imágenes del sitio web sólo
              para fines de uso personal. El cliente acepta que no publicará
              material de este sitio web (incluida la publicación en otro sitio
              web); vender, alquilar o sublicenciar material del sitio web;
              mostrar cualquier material del sitio web en público o al público;
              reproducir, duplicar, copiar o explotar material de este sitio web
              con fines comerciales; editar o modificar cualquier material en el
              sitio web; o redistribuir material de este sitio web (excepto el
              contenido disponible de manera específica y expresa para su
              redistribución). Donde y si el contenido está específicamente
              disponible para su redistribución, sólo se puede redistribuir
              dentro de su organización y el usuario es totalmente responsable
              de su uso o mal uso posterior.
            </p>
            <p>
              <b>E. Estos Términos se consideran un contrato electrónico</b> -
              Todos los servicios o productos mostrados, comercializados,
              ofrecidos o de otro modo accedidos, publicados o comprados a
              través del sitio/aplicación, se realizan bajo la responsabilidad
              del cliente y por contrato en formato electrónico. La ley federal
              de firma electrónica de EE. UU. se aplica a este acuerdo y a todas
              las transacciones a través del sitio web/aplicación de Pizzería
              Habana. Además de esta muestra en el navegador o en la aplicación,
              Pizzería Habana pondrá a disposición de las personas interesadas
              estos Términos en formato electrónico a solicitud expresa. Puede
              solicitar acceso a estos documentos electrónicos en Adobe Acrobat
              o en formatos de texto estándar. En todo momento el cliente se
              reserva el derecho de solicitar y recibir estos documentos previa
              solicitud al correo electrónico de Pizzería Habana
              soporte@pizzeriahabana.com.
            </p>
            <p>
              <b>F. Legitimidad de los medios de pago</b> - El cliente declara y
              garantiza que es el titular autorizado de cualquier tarjeta
              bancaria registrada en su cuenta de Pizzería Habana. Si es
              empleado de una empresa u otra entidad o está actuando en nombre
              de una empresa, debe registrar el nombre de la empresa como el
              usuario de la cuenta y debe estar autorizado por la compañía para
              aceptar los Términos de uso en nombre de ese empresario, empresa o
              entidad. De lo contrario y de acuerdo con estos Términos, la
              cuenta podrá ser deshabilitada o cancelada.
            </p>
            <h4 className="my-4">
              II. USO DE CUENTA, SITIO Y APLICACIÓN DE NUESTROS SERVICIOS.
            </h4>
            <p>
              <b>Configuración y cancelación de cuenta:</b> Lo siguiente aplica
              para la configuración de la cuenta de usuario. Cualquier duda que
              presente puede contactar con Pizzería Habana llamando al Servicio
              de Atención al Cliente +1 (305)859-4472 o a través del correo
              electrónico soporte@pizzeriahabana.com.
            </p>
            <OrderedList>
              <li>
                <b>Configuración de perfil de la cuenta</b> - Para utilizar el
                servicio de Pizzería Habana, el cliente tiene que crear una
                cuenta. Al crear una cuenta, el cliente debe proporcionar
                información veraz conforme a lo solicitado en el formulario de
                registro. El cliente acepta que la información que ofrece es
                verdadera, exacta y completa. Proporcionar información falsa,
                inexacta o engañosa al crear o actualizar una cuenta en Pizzería
                Habana viola estos términos y puede resultar en la cancelación
                inmediata de la cuenta. Cualquier cambio que el cliente realice
                después de ser creada la cuenta, tales como el número de
                teléfono, deberá actualizarlo con la mayor brevedad posible en
                el Perfil de la cuenta y será totalmente su responsabilidad la
                actualización de dicha información. Toda la información
                personal, incluyendo la información financiera (los "datos") que
                el cliente proporcione, serán tratados de acuerdo con la
                Declaración de Privacidad. Una vez registrado, el cliente tiene
                un nombre de usuario y una contraseña y es completamente
                responsable de todas las actividades que ocurran bajo su cuenta,
                incluyendo el uso no autorizado de su(s) tarjeta(s) bancaria(s).
                El cliente debe notificar inmediatamente cualquier uso no
                autorizado de su cuenta a Pizzería Habana llamando al
                +1(305)859-4472 o escribiendo al correo electrónico
                soporte@pizzeriahabana.com.
              </li>
              <li>
                <b>Configuración de una cuenta</b> - Con Pizzería Habana puede
                crearse una cuenta a través del sitio web, la aplicación móvil o
                una llamada telefónica. Usted necesitará crear un nombre de
                usuario que responderá a una dirección de correo electrónico
                válida para relacionarla con su cuenta y necesitará ingresar una
                contraseña. La actualización de cualquier información de la
                cuenta será su responsabilidad. Debe proteger su información y
                no revelar su contraseña a terceros. Si usted olvida o pierde la
                contraseña, deberá solicitar una nueva contraseña en la sección
                "Recuperar contraseña" del sitio web o de la aplicación móvil.
                Cada dirección de correo electrónico y contraseña deben ser
                utilizados por un único usuario y no son transferibles.
              </li>
              <li>
                <b>Cierre o cancelación de su cuenta</b> - El cliente puede
                cerrar su cuenta de Pizzería Habana en cualquier momento. Debe
                enviar un email a soporte@pizzeriahabana.com desde el mismo
                correo que fue registrado en la cuenta.
              </li>
            </OrderedList>
            <h4 className="my-4">III. TÉRMINOS Y CONDICIONES GENERALES.</h4>
            <p>
              <b>
                A. Uso de cookies por el sitio web o la aplicación y la política
                de privacidad
              </b>{' '}
              - El sitio web de Pizzería Habana utiliza cookies en el desempeño
              de los servicios y la operación. Al utilizar el sitio web y
              aceptar los Términos, el cliente acepta que Pizzería Habana puede
              usar cookies de acuerdo con su Política de Privacidad, la cual se
              incorpora en este acuerdo como referencia.
            </p>
            <p>
              <b>
                B. No hay garantías en el servicio de terceros a través del
                sitio o aplicación
              </b>{' '}
              - SUPLEMENTO A LOS TÉRMINOS ANTERIORES: PIZZERÍA HABANA NO OFRECE
              NINGÚN TIPO DE GARANTÍA EXPRESA O IMPLÍCITA, INCLUYENDO, PERO NO
              LIMITADA A GARANTÍAS DE COMERCIABILIDAD O IDONEIDAD PARA UN
              PROPÓSITO EN PARTICULAR CON RESPECTO A LAS DESCARGAS Y DESCUENTOS
              DE TERCEROS (SERVICIO DE TERCEROS). TAMPOCO SERÁ RESPONSABLE DE
              CUALQUIER COSTO O DAÑO QUE SURJA DIRECTA O INDIRECTAMENTE DE
              CUALQUIER TRANSACCIÓN DE SERVICIO DE TERCEROS, EL USO DEL SITIO
              WEB Y SOFTWARE SUBYACENTE. PIZZERÍA HABANA NO GARANTIZA QUE LA
              APLICACIÓN Y EL SOFTWARE SUBYACENTE DE SU SITIO O SU APLICACIÓN
              MÓVIL ESTÉN LIBRES DE ERRORES O FUNCIONARÁN SIN FALLA O
              INTERRUPCIÓN, O QUE CUALQUIER DEFECTO EN LA APLICACIÓN Y EL
              SOFTWARE SUBYACENTE SERÁ CORREGIDO POR EL TITULAR DEL SOFTWARE
              BAJO GARANTÍA.
            </p>
            <p>
              <b>C. Limitación de responsabilidad expresada</b> - EN NINGÚN CASO
              PIZZERÍA HABANA SERÁ RESPONSABLE ANTE EL USUARIO POR (I) CUALQUIER
              DAÑO INCIDENTAL, CONSECUENTE O INDIRECTO (INCLUYENDO, PERO SIN
              LIMITARSE, A DAÑOS POR INTERRUPCIÓN, POR PÉRDIDAS, PÉRDIDAS DE
              PROPIEDAD O IGUAL) DERIVADAS DEL USO DE O INCAPACIDAD PARA
              UTILIZAR LA APLICACIÓN Y EL SOFTWARE SUBYACENTE, INCLUSO SI
              PIZZERÍA HABANA O SUS REPRESENTANTES AUTORIZADOS HAN SIDO
              NOTIFICADOS DE LA POSIBILIDAD DE DICHOS DAÑOS, O (II) CUALQUIER
              RECLAMO ATRIBUIBLE A ERRORES, OMISIONES U OTRAS INEXACTITUDES DE
              UN SOFTWARE, A TRAVÉS DEL SITIO WEB O LA APLICACIÓN MÓVIL.
            </p>
            <p>
              <b>D. Indemnización</b> - El usuario/cliente acepta indemnizar,
              defender y mantener a Pizzería Habana, sus funcionarios,
              directores, empleados, agentes, accionistas, licenciantes,
              proveedores, socios comerciales y cualquier beneficiario efectivo,
              de y contra todas las pérdidas, gastos, daños y costos, incluidos
              los honorarios razonables de abogados, resultantes del uso de este
              sitio web por parte del usuario; incluida, entre otras, cualquier
              violación de este Acuerdo por parte del usuario. Las disposiciones
              de este párrafo son para beneficio de Pizzería Habanay sus
              funcionarios, directores, empleados, agentes, accionistas,
              otorgantes de licencias, proveedores, socios comerciales y
              beneficiarios reales con respecto a cualquier producto vendido o
              comprado a través del sitio web de Pizzería Habana. Cada una de
              estas personas o entidades tendrá derecho a hacer valer y cumplir
              estas disposiciones directamente contra el cliente en su propio
              nombre.
            </p>
            <p>
              <b>E. Dispositivos, software y planes de datos</b> - Los servicios
              de Pizzería Habana pueden requerir ciertos dispositivos (por
              ejemplo: computadoras, audífonos, bocinas, micrófonos, teléfonos
              celulares, etc), software, conexión a Internet, planes de
              celulares y de datos. Es responsabilidad del cliente disponer de
              todos los dispositivos, conexiones y planes necesarios para
              utilizar los servicios de Pizzería Habana y pagar todas las
              tarifas que cobren terceros e impuestos relacionados con el uso
              que realice de nuestros servicios. El cliente deberá verificar con
              sus proveedores de Internet y compañías de servicios telefónicos
              las tarifas que pudieran corresponder por el uso de los servicios
              de Pizzería Habana.
            </p>
            <p>
              <b>F. Políticas de Pizzería Habana</b>
            </p>
            <OrderedList>
              <li>
                <b>Política de privacidad</b> - Por favor toma unos minutos para
                leer la siguiente política para que comprendas cómo tratamos tu
                información. A medida que vayamos continuamente mejorando y
                expandiendo nuestros servicios, esta política puede ir
                cambiando. Por favor revísala periódicamente.
                <p className="mt-3">
                  Esta Política de Privacidad aplica a los sitios web de
                  Pizzería Habana en línea y móviles.
                </p>
                <p>
                  a) La información personal del cliente será tratada de forma
                  confidencial y no será revelada a terceros. Una vez registrada
                  la información personal del cliente, la misma quedará sujeta a
                  los términos de la Política de Privacidad, y excepto en el
                  caso de que dicha revelación sea necesaria por imposición de
                  la ley o en virtud de un acuerdo o petición emitida por el
                  Regulador Fiscal u otra autoridad legal o reguladora
                  competente, deberá ser accesible sólo para nosotros, nuestros
                  agentes, o por una empresa controlada por nosotros; y será
                  procesada o utilizada por nosotros para los propósitos del
                  servicio que brindamos (y que el cliente solicita), y de una
                  manera compatible con el cumplimiento de nuestra obligación en
                  virtud del Acuerdo. La información personal del cliente se
                  archiva en nuestro servidor de manera encriptada para proteger
                  su privacidad y brindar la mayor seguridad.
                </p>
                <p>
                  b) Información que recopilamos y permisos.
                  <br />
                  Recopilamos la siguiente información de los clientes de manera
                  directa o indirecta:
                  <br />
                  Nombres y apellidos.
                  <br />
                  Correo electrónico.
                  <br />
                  Número de teléfono.
                  <br />
                  Métodos de pago.
                  <br />
                  Dirección particular.
                  <br />
                  Historial de compras
                  <br />
                  Esta información personal es necesaria para brindar nuestros
                  servicios y darle una experiencia personalizada a nuestro
                  cliente. Se pedirá al usuario su aceptación de nuestros
                  Términos y Condiciones y de nuestra Política de Privacidad
                  para guardar su información personal y crear su cuenta en
                  Pizzería Habana.
                </p>
                <p>
                  c) Uso de la información personal del cliente.
                  <br />
                  Podemos usar esta información para lo siguiente:
                  <br />
                  Para brindar al cliente información sobre productos o
                  servicios que le puedan interesar.
                  <br />
                  Para notificar al cliente acerca de cualquier cambio en
                  nuestro sitio web/aplicación o en nuestros servicios.
                  <br />
                  Para asegurarnos que el contenido de nuestro sitio
                  web/aplicación sea presentado al cliente de la manera más
                  efectiva.
                  <br />
                  Para brindar la mejor atención al cliente.
                  <br />
                  Para propósitos de seguridad y prevención de fraude.
                  <br />
                  Para análisis de marketing.
                </p>
                <p>
                  d) Otros permisos.
                  <br />
                  La aplicación móvil de Pizzería Habana, dependiendo de la
                  plataforma, pudiera pedir permiso a nuestros clientes para
                  utilizar recursos del teléfono (o del dispositivo) como el
                  micrófono para el manejo y la grabación de audio. Estos
                  recursos resultan necesarios para cumplir con el servicio de
                  comunicación que brindamos, por ejemplo, las llamadas
                  telefónicas para realizar pedidos.
                </p>
                <p>
                  e) Eliminar la información personal del cliente de nuestro
                  sistema.
                  <br />
                  El cliente puede eliminar su cuenta del sistema de Pizzería
                  Habana cuando lo desee. Para ello debe comunicar su solicitud
                  de eliminación de cuenta al equipo de atención al cliente a
                  través del correo electrónico soporte@pizzeriahabana.com o
                  llamando al teléfono +1(305)859-4472. El cliente deberá
                  utilizar el mismo correo electrónico y/o el mismo número
                  telefónico registrado en su cuenta. En un breve intervalo de
                  tiempo toda la información personal del cliente será eliminada
                  del registro de Pizzería Habana.
                </p>
                <p>
                  Al utilizar nuestros servicios y aceptar nuestros Términos y
                  Condiciones, usted acepta esta Política de Privacidad.
                </p>
              </li>
              <li>
                <b>Política de reclamaciones bancarias</b> - Si existiera alguna
                duda referente a alguna transacción completada en su cuenta, le
                rogamos que se ponga en contacto con nuestro servicio de
                Atención al Cliente antes de presentar una reclamación al banco.
                De esta manera podemos ayudarle y dar solución a su problema sin
                incurrir en litigios, cargos bancarios y acortando además el
                tiempo de resolución del problema.
              </li>
              <li>
                <b>En caso de una reclamación bancaria a la empresa</b> -
                Pizzería Habana tendrá el derecho de deshabilitar el acceso a su
                cuenta durante el periodo en que se lleve a cabo la
                investigación de los hechos declarados en la reclamación
                bancaria y, una vez terminado el proceso, Pizzería Habana le
                contactará para dar a conocer los resultados de la investigación
                y las particularidades del uso de su cuenta a partir de ese
                momento. Una vez terminado el proceso, Pizzería Habana tiene el
                derecho de inhabilitar permanente su cuenta de usuario. Ante un
                comportamiento irregular y uso indebido del sistema, Pizzería
                Habana finalizará permanentemente las relaciones con el cliente
                involucrado. Ante la duda que pueda surgir sobre una transacción
                realizada por usted o por alguna persona autorizada al utilizar
                su cuenta, solicitamos que se comunique con Pizzería Habana lo
                antes posible, antes de hacerlo con sus instituciones bancarias.
                De esa forma podremos cooperar prontamente en el proceso de
                resolución del problema.
              </li>
            </OrderedList>
            <p>
              <b>G. Tarifas y métodos de pagos.</b>
            </p>
            <OrderedList>
              <li>
                <b>Transacciones con tarjetas de débito o crédito</b> - Los
                pagos se realizan con tarjetas de débito y/o crédito VISA,
                MasterCard o DISCOVER. Otro método de pago como: tarjeta de
                regalo, tarjeta prepagada, efectivo o cualquier otro medio, no
                será aceptado. Todos estos métodos de pago autorizados deben
                tener una dirección de facturación y un banco emisor de la
                tarjeta válida. Una vez solicitada una orden, se procede a la
                autorización de la transacción. Tras verificar que existen
                fondos disponibles en la tarjeta se procesa la orden. Pizzería
                Habana no está obligada a realizar ningún tipo de
                autentificación o verificación de identidad adicionales a otras
                que no sean las que Pizzería Habana considere oportunas y
                suficientes para proteger la seguridad y mantener el buen uso de
                este sitio web y/o la aplicación móvil. Todos los débitos
                concluidos después de una autentificación o verificación de
                identidad correctas son responsabilidad del usuario únicamente.
                <br />
                Pizzería Habana aceptará responsabilidad por la no ejecución o
                ejecución defectuosa de órdenes hechas a través del sitio web o
                la aplicación móvil, sujeto a su adhesión a este Acuerdo, el uso
                correcto del sitio web siguiendo las instrucciones de Pizzería
                Habana, y la ausencia de cualquier falsedad, fraude o
                negligencia por parte del cliente. Dicha responsabilidad, si
                ocurriera, se limitará estrictamente al valor de la orden por
                transacciones no ejecutadas o defectuosas.
              </li>
              <li>
                <b>Tarifas y cargos de Pizzería Habana</b> - Pizzería Habana
                cobra exactamente el monto mostrado al final de su transacción.
                No existen cargos ocultos. Además de las tarifas por
                procesamiento y servicios, los proveedores externos pueden
                cobrar por la utilización de los Servicios o por las
                conversiones de moneda. Las tarifas de los proveedores externos
                pueden variar según las compañías de servicios telefónicos,
                socios locales, empresas locales de telecomunicaciones,
                operadores de teléfonos celulares, ubicación y tipo de
                servicios. Es su responsabilidad averiguar si los usuarios de
                los Servicios o quienes adquieran créditos incurrirán en cargos
                de los proveedores externos y deberán pagar dichos cargos.
                Pizzería Habana no asume responsabilidad alguna por cualquier
                discrepancia entre la información sobre tarifas que aparece en
                el sitio y las que en realidad sean cobradas por su proveedor.
              </li>
              <li>
                <b>Promociones</b> - Pizzería Habana ofrecerá promociones y
                campañas para todos los usuarios que hayan dado su
                consentimiento para recibir material de propaganda. La
                información acerca de las promociones estará disponible en el
                sitio web, la aplicación móvil y se enviará a través de correos
                electrónicos, SMS o notificaciones PUSH. Se incluirá en cada
                campaña la fecha promocional, condiciones y limitaciones en caso
                de existir alguna. Estas campañas pueden ofrecer promociones
                especiales por parte de Pizzería Habana, por lo que las
                promociones pueden ser limitadas en tiempo y uso de los
                beneficios que la misma ofrece. Los Términos y Condiciones
                generales para promociones y campañas incluyen, pero no se
                limitan a:
                <p className="mt-3">
                  a) Ciertas ofertas que sólo serán válidas para los nuevos
                  usuarios al inscribirse (cancelar una cuenta y/o luego
                  registrarse nuevamente con el mismo número telefónico o email
                  no será considerado como un nuevo registro).
                </p>
                <p>
                  b) Pizzería Habana se reserva el derecho de rechazar o
                  concluir una campaña en cualquier momento y por cualquier
                  razón, a criterio absoluto de Pizzería Habana.
                </p>
                <p>
                  c) Pizzería Habana se reserva el derecho de modificar, añadir
                  o eliminar las condiciones de cualquier oferta luego de ser
                  promocionada.
                </p>
              </li>
            </OrderedList>
            <p>
              <b>H. COMUNICACIÓN Y NOTIFICACIONES</b> - Pizzería Habana enviará
              notificaciones y comunicaciones usando la información de contacto
              almacenada en el perfil de su cuenta. Sus datos podrán ser usados
              para notificarle sobre información referente a nuestras ofertas,
              al estado de sus transacciones y cualquier otra información de
              utilidad. El cliente puede aceptar o no recibir estas
              notificaciones. De la misma manera si en algún momento desea dejar
              de recibir futuras notificaciones, se incluyen instrucciones
              precisas de cómo suspender el servicio al final de cada correo
              electrónico o SMS y en la sección Perfil de la aplicación móvil.
              Esta es la manera en que puede revocar el permiso otorgado
              anteriormente a Pizzería Habana para recibir mensajes de texto o
              de correo electrónico. Puede solicitar también dejar de recibir
              notificaciones escribiendo al correo electrónico:
              soporte@pizzeríahabana.com.
            </p>
            <p>
              <b>I. SUSPENSIÓN DEL SERVICIO</b> - Pizzería Habana puede terminar
              este Acuerdo y el cliente abandonar el uso del sitio web y/o de
              los servicios prestados si: ocurriese un fallo o un mal
              funcionamiento en cualquier parte del Sistema utilizado en
              relación con el sitio web o la aplicación móvil. De la misma
              manera se procederá si estuviera en riesgo la seguridad del
              Sistema. Dicha suspensión puede ser de carácter temporal,
              considerando un periodo razonable hasta la solución del problema
              sin incurrir en ninguna responsabilidad hacia el cliente.
            </p>
            <p>
              <b>J. SU CONTRIBUCIÓN</b> - Al enviar a Pizzería Habana cualquier
              comentario, sugerencia, idea y/o materiales en relación con el
              sitio web, la aplicación o los servicios prestados, usted acepta
              que podemos utilizar, reproducir, publicar, modificar, adaptar y
              transmitir dicha comunicación de forma gratuita a terceros y sin
              restricciones, sin perjuicio de las obligaciones según lo
              dispuesto en la Declaración de Privacidad.
            </p>
            <p>
              <b>K. SEGURIDAD, MANTENIMIENTO Y DISPONIBILIDAD</b> - Con el
              objetivo de mejorar nuestros servicios y proporcionar una mayor
              calidad a los clientes, Pizzería Habana puede, por razones de
              seguridad, de mantenimiento (ya sean de emergencia o
              planificadas), actualizaciones u otras razones, interrumpir
              ciertos o todos los Servicios al cliente; y/o demorar la
              implementación de cualquier Servicio nuevo; y/o retirar, sustituir
              o volver a emitir contraseñas; y/o cambiar procedimientos de
              autentificación o procesos para acceder al sitio web o los
              Servicios. Siempre haremos todo lo posible por minimizar las
              molestias ocasionadas.
            </p>
            <p>
              <b>L. TERMINACIÓN DEL ACUERDO</b> - Pizzería Habana puede concluir
              este Acuerdo y retirar el uso del sitio web y/o de los servicios
              prestados inmediatamente después del incumplimiento de cualquiera
              de los Términos o cuando existan fundadas razones para la
              terminación del mismo. De la misma manera se finalizará si existen
              motivos para creer que cualquiera de los servicios han sido
              prestados por negligencia, de manera ilegal o fraudulenta por
              parte del cliente o por un tercero como consecuencia de su
              negligencia o imprudencia. Una vez tomada la decisión, el cliente
              será notificado por escrito, vía email. Este Acuerdo no tiene un
              mínimo o duración finita y seguirá siendo obligatorio para las
              partes hasta que termine. Pizzería Habana se reserva el derecho de
              procesar o cancelar cualquier transacción en curso en la
              terminación de este Acuerdo o en la suspensión o retirada de los
              Servicios. Pizzería Habana no se hace responsable de cualquier
              pérdida que pueda incurrir como resultado de cualquier transacción
              que no se procese como parte de los Servicios después de la
              terminación del Acuerdo o después de cualquier suspensión o
              retirada de los Servicios.
            </p>
            <p>
              <b>M. VARIACIONES DEL ACUERDO</b> - Pizzería Habana se reserva el
              derecho a modificar este Acuerdo. Los cambios se harán efectivos
              en un periodo hábil de hasta quince (15) días después de notificar
              al cliente de tal modificación mediante correo postal, correo
              electrónico, o a través de la publicación de un mensaje en el
              sitio web. El cliente estará en todo el derecho, al recibir el
              aviso de cualquier modificación al presente Acuerdo, de
              finalizarlo de manera inmediata mediante notificación por escrito
              a soporte@pizzeriahabana.com, sin perjuicio de los derechos u
              obligaciones que hayan surgido antes de la fecha de terminación,
              incluida la responsabilidad por cualquier endeudamiento u otra
              obligación financiera, legal, o de otro tipo que se ha presentado
              con anterioridad a la fecha de terminación.
            </p>
            <p>
              <b>N. PROPIEDAD INTELECTUAL</b> - Pizzería Habana posee todos los
              derechos en cualquier logotipo que lo acompaña, así como nombres
              del dominio https://www.pizzeriahabana.com. De igual manera, se le
              atribuyen derechos de propiedad en todos los datos, información,
              Sistemas u otros materiales utilizados para la prestación de los
              Servicios, siendo Pizzería Habana o sus licenciados el único
              propietario. El cliente sólo deberá hacer uso de los mismos con el
              propósito de recibir los servicios tal y como se refleja en el
              Acuerdo. Cualquier reproducción de los contenidos del sitio web en
              cualquier forma sin consentimiento previo, está prohibido. Ante el
              incumplimiento o violación de dicho acuerdo Pizzería Habana tiene
              todo el derecho a solicitar protección con respecto a cualquiera
              de los derechos antes mencionados.
            </p>
            <p>
              <b>O. SERVICIO AL CLIENTE</b> - Ante cualquier inquietud, queja o
              sugerencia usted puede contactarnos a través del correo
              electrónico soporte@pizzeriahabana.com. También puede llamar a
              nuestro Servicio de Atención al Cliente +1(305)859-4472.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TermsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
